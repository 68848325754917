exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-add-new-book-tsx": () => import("./../../../src/pages/admin/add-new-book.tsx" /* webpackChunkName: "component---src-pages-admin-add-new-book-tsx" */),
  "component---src-pages-admin-all-author-pages-tsx": () => import("./../../../src/pages/admin/all-author-pages.tsx" /* webpackChunkName: "component---src-pages-admin-all-author-pages-tsx" */),
  "component---src-pages-admin-all-books-tsx": () => import("./../../../src/pages/admin/all-books.tsx" /* webpackChunkName: "component---src-pages-admin-all-books-tsx" */),
  "component---src-pages-admin-all-users-tsx": () => import("./../../../src/pages/admin/all-users.tsx" /* webpackChunkName: "component---src-pages-admin-all-users-tsx" */),
  "component---src-pages-admin-amazon-orders-tsx": () => import("./../../../src/pages/admin/amazon-orders.tsx" /* webpackChunkName: "component---src-pages-admin-amazon-orders-tsx" */),
  "component---src-pages-admin-edit-book-[bookid]-tsx": () => import("./../../../src/pages/admin/edit-book/[bookid].tsx" /* webpackChunkName: "component---src-pages-admin-edit-book-[bookid]-tsx" */),
  "component---src-pages-admin-upload-amazon-data-tsx": () => import("./../../../src/pages/admin/upload-amazon-data.tsx" /* webpackChunkName: "component---src-pages-admin-upload-amazon-data-tsx" */),
  "component---src-pages-admin-view-book-[bookid]-tsx": () => import("./../../../src/pages/admin/view-book/[bookid].tsx" /* webpackChunkName: "component---src-pages-admin-view-book-[bookid]-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

